import Vue from 'vue'
import App from './App.vue'
import router from '@/router' //引入路由
import 'amfe-flexible'
import Vant from 'vant';
import 'vant/lib/index.css';
import '@/css/reset.scss'
import '@/css/common.css'
import Element from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import VueAwesomeSwiper from 'vue-awesome-swiper';
Vue.use(VueAwesomeSwiper);

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title + " | BionicM"
  }
  next()
})


Vue.config.productionTip = false
// 标签内联样式px转rem
import {px2rem} from './utils/rem.js'
Vue.prototype.$px2rem = px2rem
Vue.use(Vant);
Vue.use(Element)
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
