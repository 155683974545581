import axios from "axios";
const config = {
    // proxy: "http://192.168.1.32:58031", // 本地地址
    //proxy: "http://111.53.214.119:8031/prod-api",//测试
    proxy: "https://bionicm.com/prod-api",//正式
};
// axios基本配置
const api = axios.create({
    timeout: 30000,
    withCredentials: true,
});
// 请求开始拦截
api.interceptors.request.use(
    (conf) => {
        // console.log(conf);
        return conf;
    },
    (error) => ({ status: 0, msg: error.message })
);
// 请求返回拦截
api.interceptors.response.use(
    (response) => {
        // if (
        //     response.data.code === -1 &&
        //     response.data.code === 401
        // ) {
        //     // token失效
        //     return Promise.resolve(response).then(checkCode);
        // }
        return Promise.resolve(response).then(checkCode);
    },
    (error) => {
        return Promise.reject(error);
    }
);
// 后台自定义 code错误处理
const checkCode = (res) => {
    if (res) {
        if (res.data.code === 200) {
            //code为200成功
            const returnData = {
                code: 200,
                success: true,
                rows: res.data.rows ? res.data.rows : res.data.data ? res.data.data : [],
                total: res.data.total ? res.data.total : null,
                msg: res.data.message,
            };
            return returnData;
        } else {
            //统一处理错误
            const returnData = {
                success: false,
                code: res.code,
                msg: res.data.message || res.data.msg,
            };
            return returnData;
        }
    } else {
        const returnData = {
            success: false,
            code: -1,
            msg: "没有返回值",
        };
        return returnData;
    }
};

// 解析参数
const formatParams = (method = "GET", params) => {
    //headers配置
    const headers = {
        "Content-Type": "application/json;charset=utf-8",
        // "Language": getCache('lang') == 'en' ? 'en' : '' ,
    };
    switch (method) {
        case "POST":
            return {
                headers,
                method,
                data: params,
            };
        case "PUT":
            return {
                headers,
                method,
                data: params,
            };
        case "DELETE":
            return {
                headers,
                method,
                data: params,
            };
        case "GET":
            return {
                headers,
                method,
                params,
            };
        default:
            return {
                headers,
                method,
                params,
            };
    }
};

export { api, formatParams, config };