import { api, formatParams, config } from "@/utils/request";

// 获取新闻分页列表
export function updateList(params) {
    return api(
        Object.assign(formatParams("GET", params), {
            url: `${config.proxy}/newPosts/pageList`,
        })
    );
}

// 获取新闻/专栏详情
export function updateDetail(params) {
    return api(
        Object.assign(formatParams("GET", params), {
            url: `${config.proxy}/newPosts/load`,
        })
    );
}

// 获取新闻/专栏详情
export function downFile(params) {
    return api(
        Object.assign(formatParams("GET", params), {
            url: `${config.proxy}/downloadUrlFile`,
        })
    );
}

// 获取award详情
export function awardDetail(params) {
    return api(
        Object.assign(formatParams("GET", params), {
            url: `${config.proxy}/posts/loadByUrl`,
        })
    );
}
