import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter); //使用插件

// 在路由内重写push或replace方法（解决控制台报错）
let originPush = VueRouter.prototype.push
let originReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location) {
  return originPush.call(this, location).catch(err => err)
}
VueRouter.prototype.replace = function push(location) {
  return originReplace.call(this, location).catch(err => err)
}

//引入路由组件
//配置路由
export default new VueRouter({
  mode: 'history',
  routes: [
    { path: "/", name: 'Home', component: () => import("@/pages/en/Home"),meta:{title:"Home"} },
    { path: "/about", component: () => import("@/pages/en/About"),meta:{title:"About Us"} },
    { path: "/life", component: () => import("@/pages/en/Life"),meta:{title:"Life"} },
    { path: "/product", name: 'productPage', component: () => import("@/pages/en/Product"),meta:{title:"Product",description: 'Balance & Symmetry',
        keywords: 'Balance & Symmetry'} },
    { path: "/founders", name: 'Founders', component: () => import("@/pages/en/Founders"),meta:{title:"Founders Message"} },
    { path: "/news", name: 'Updates', component: () => import("@/pages/en/Updates"),meta:{title:"News"} },
    { path: '/newsDetail/:id', name: 'UpdateDetail', component: () => import("@/pages/en/Updates/detail"),meta:{title:"News Detail"}},
    { path: "/voice", name: 'voicePage', component: () => import("@/pages/en/Product/voice"),meta:{title:"Voice"} },
    { path: "/contact", name: 'Contact', component: () => import("@/pages/en/Contacts"),meta:{title:"Contact"} },
    { path: "/faqs", name: 'Faqs', component: () => import("@/pages/en/Faqs"),meta:{title:"FAQ"}},
    { path: "/column", name: 'Column', component:()=> import("@/pages/en/Column"),meta:{title:"Column"} },
    { path: "/columnDetail/:id", name: 'ColumnDetail', component: () => import("@/pages/en/Column/detail"),meta:{title:"Column Detail"}},
    { path: "/hotline", name: 'Hotline', component: () => import("@/pages/en/other/hotlineIndex"),meta:{title:"Compliance on Public Research Funding"}},
    { path: "/privacy", name: 'Privacy', component: () => import("@/pages/en/other/privacyIndex"),meta:{title:"Privacy Policy"}},

    { path: "/jp", name: 'JapHome', component: () => import("@/pages/jap/Home"),meta:{title:"ホームページ"} },
    { path: "/jp/about", component: () => import("@/pages/jap/About"),meta:{title:"会社概要"} },
    { path: "/jp/awardDetail", name: 'JapAwardDetail', component: () => import("@/pages/jap/About/awardDetail") },
    { path: "/jp/life", component: () => import("@/pages/jap/Life"),meta:{title:"Life"}  },
    { path: "/jp/product", name: 'JapProductPage', component: () => import("@/pages/jap/Product"),meta:{title:"製品",description: 'Balance & Symmetry',
      keywords: 'Balance & Symmetry'} },
    { path: "/jp/founders", name: 'JapFounders', component: () => import("@/pages/jap/Founders"),meta:{title:"CEOからのメッセージ"}  },
    { path: "/jp/news", name: 'JapUpdates', component: () => import("@/pages/jap/Updates"),meta:{title:"ニュース"} },
    { path: '/jp/newsDetail/:id', name: 'JapUpdateDetail', component: () => import("@/pages/jap/Updates/detail"),meta:{title:"ニュースの詳細"}},
    { path: "/jp/voice", name: 'JapVoicePage', component: () => import("@/pages/jap/Product/voice"),meta:{title:"ユーザーの声"} },
    { path: "/jp/contact", name: 'JapContact', component: () => import("@/pages/jap/Contacts"),meta:{title:"お問い合わせ"} },
    { path: "/jp/faqs", name: 'JapFaqs', component: () => import("@/pages/jap/Faqs"),meta:{title:"FAQ"}},
    { path: "/jp/column", name: 'JapColumn', component:()=> import("@/pages/jap/Column"),meta:{title:"コラム"} },
    { path: "/jp/columnDetail/:id", name: 'JapColumnDetail', component: () => import("@/pages/jap/Column/detail"),meta:{title:"コラムの詳細"}},
    { path: "/jp/hotline", name: 'JapHotline', component: () => import("@/pages/jap/other/hotlineIndex"),meta:{title:"公的研究費不正防止について"}},
    { path: "/jp/privacy", name: 'JapPrivacy', component: () => import("@/pages/jap/other/privacyIndex"),meta:{title:"プライバシーポリシー"}},
    {
      path: '*',
      redirect: '/'
    }
  ],
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }; // 将页面滚动条置顶
  }
});
