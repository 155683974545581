// 标签内联样式px转rem
export const px2rem = (px) => {
    if(window.innerWidth < 768){
        if (/%/ig.test(px)) {
            return px
        } else {
            return (parseFloat(px) / 37.5) + 'rem'
        }
    }else {
        if (/%/ig.test(px)) {
            return px
        } else {
            return (parseFloat(px) / 192) + 'rem'
        }
    }
}