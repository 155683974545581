function isNowMonth(year,month,day){
    const monthAbbreviations = {
        "Jan": 0,
        "Feb": 1,
        "Mar": 2,
        "Apr": 3,
        "May": 4,
        "Jun": 5,
        "Jul": 6,
        "Aug": 7,
        "Sep": 8,
        "Oct": 9,
        "Nov": 10,
        "Dec": 11
    }
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    if(currentYear === year){
        const numericMonth = monthAbbreviations[month];
        const targetDate = new Date(year, numericMonth, day);
        const currentTimeInMs = currentDate.getTime();
        const targetTimeInMs = targetDate.getTime();
        const timeDiffInMs = currentTimeInMs - targetTimeInMs;
        const daysDiff = Math.round(timeDiffInMs / (1000 * 60 * 60 * 24));
        if (daysDiff > 30) {
            return false
        } else {
            return true
        }
    }else {
        return false
    }
}

function exchangeDate(date){
    const parts = date.match(/(\d{4})年(\d{1,2})月/);
    const year = parts[1];
    const month = parseInt(parts[2]) - 1;
    const dateObj = new Date(year,month);

    const year1 = dateObj.getFullYear();
    const month1 = dateObj.getMonth();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthAbbreviation = monthNames[month1];
    return monthAbbreviation + ". " + year1;
}


function changeEnDate(dateString) {
    const date = new Date(dateString);
    const year1 = date.getFullYear();
    const month1 = date.getMonth();
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    const monthAbbreviation = monthNames[month1];
    return monthAbbreviation + ". " + year1;
}

export {isNowMonth,exchangeDate,changeEnDate}