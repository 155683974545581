import { api, formatParams, config } from "@/utils/request";

// 保存访问路径
export function insert(params) {
  return api(
    Object.assign(formatParams("POST", params), {
      url: `${config.proxy}/reqSource/insert`,
      data: params
    })
  );
}


// 获取表单
export function getFormData(params) {
    return api(
        Object.assign(formatParams("GET", params), {
            url: `${config.proxy}/posts/load`,
        })
    );
}

// 获取表单
export function submitForm(params) {
    return api(
        Object.assign(formatParams("POST", params), {
            url: `${config.proxy}/mail/sendByType`,
        })
    );
}

// 通过url获取网页信息
export function getDataByPage(params) {
    return api(
        Object.assign(formatParams("GET", params), {
            url: `${config.proxy}/html/getHtmlByUrl`,
        })
    );
}